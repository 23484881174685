<template>
	<div class="mdc-select">
		<input type="hidden" :name="name" :id="id" v-bind="attrs" v-on="listeners" />
		<div class="mdc-select__anchor"
			role="button"
			aria-haspopup="listbox"
			aria-labelledby="demo-label demo-selected-text">
			<span id="selected-text" class="mdc-select__selected-text">{{ selected }}</span>
			<span class="mdc-select__dropdown-icon">
				<svg
					class="mdc-select__dropdown-icon-graphic"
					viewBox="7 10 10 5">
					<polygon
						class="mdc-select__dropdown-icon-inactive"
						stroke="none"
						fill-rule="evenodd"
						points="7 10 12 15 17 10">
					</polygon>
					<polygon
						class="mdc-select__dropdown-icon-active"
						stroke="none"
						fill-rule="evenodd"
						points="7 15 12 10 17 15">
					</polygon>
				</svg>
			</span>
			<span id="select-label" class="mdc-floating-label" :class="{'mdc-floating-label--float-above': attrs.value}" v-html="attrs.required ? `${label}*` : label"></span>
		</div>

		<div class="mdc-select__menu mdc-menu mdc-menu-surface" role="listbox">
			<ul class="mdc-list">
				<li class="mdc-list-item"
					role="option"
					:aria-selected="option.value === $attrs.value"
					:class="{'mdc-list-item--selected': option.value === $attrs.value}"
					:data-value="option.value"
					:key="key"
					v-for="(option, key) in options"
					>
					<span class="mdc-list-item__text">{{ option.label }}</span>
				</li>
			</ul>
		</div>
	</div>
<!--
	<div class="mdc-select">
		<i class="mdc-select__dropdown-icon"></i>
		<span class="mdc-select__selected-text"></span>
		<select
			class="mdc-select__native-control"
			v-bind="attrs"
			v-on="listeners"
			:aria-label="label"
			:id="id"
			:name="name"
		>
			<option value="" :selected="!attrs.value" disabled></option>
			<option
				:value="option.value"
				:key="key"
				v-for="(option, key) in options"
			>
				{{ option.label }}
			</option>
		</select>
		<label class="mdc-floating-label" :for="id" :class="{'mdc-floating-label--float-above': attrs.value}">{{ label }}</label>
	</div> -->
</template>

<script>
import { MDCSelect } from '@material/select'
import { v4 } from 'uuid'

export default {
	name: 'selectField',
	data: () => ({
		select: null,
	}),
	props: {
		options: {
			type: [Array],
			default: () => ([]),
		},
		id: {
			type: String,
			default: v4(),
		},
		name: String,
		label: String,
	},
	computed: {
		attrs() {
			return this.$attrs
		},
		listeners() {
			return {
				...this.$listeners,
				input: event => this.input(event),
				change: event => this.input(event),
			}
		},
		selected() {
			const found = this.options.find((option) => option.value == this.attrs.value)
			if (found) {
				return found.label
			}
			return ''
		},
	},
	mounted() {
		this.select = new MDCSelect(this.$el)
		this.select.listen('MDCSelect:change', () => {
			let _selected = this.options[this.select.selectedIndex]
			if (_selected) {
				this.$emit('input', _selected.value)
			}
		})

		setTimeout(() => {
			this.$el.querySelector('#selected-text').innerHTML = this.selected
			if (this.selected) {
				this.$el.querySelector('#select-label').classList.add('mdc-floating-label--float-above')
			}
		}, 250)
	},
	methods: {
		input(event) {
			if (event && event.target) {
				this.$emit('input', event.target.value)
			}
		},
	},
	watch: {
		attrs(val) {
			if (val && !val.value && this.select) {
				this.select.selectedIndex = -1
			}
			if (val && val.value && this.select) {
				let idx = this.options.findIndex(o => o.value === val.value)
				this.select.selectedIndex = idx
			}
		},
	},
}
</script>

<style lang="scss" scoped>
@use "@material/list/mdc-list";
@use "@material/menu-surface/mdc-menu-surface";
@use "@material/menu/mdc-menu";
@use "@material/select/mdc-select";

.mdc-select {
	$select: &;

	&__menu {
		width: 100%;
	}

	&:not(#{$select}--disabled) {

		&#{$select}--focused {
			.mdc-floating-label {
				color: modules.color_('primary');
			}
		}
	}

	&--activated {
		#{$select}__anchor {
			border-color: modules.color_('primary');
		}
	}

	.mdc-list-item {
		&:hover,
		&:focus {
			background-color: rgba(220,220,220,0.5);
		}

		&.mdc-list-item--selected {
			background-color: rgba(modules.color_('primary'), 1);
			color: modules.color_('primary', 'text');
		}
	}
}
</style>
